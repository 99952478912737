/**
 * The relative time information of the dates
 * @typedef {Object} TimeInformation
 * @property {number} elapsedTimePercentage - How far along in the date range `date` is in %.
 * @property {number} pointInTime - Whether `date` is in the past (-1), present (0) or future (1)
 */

// NOTE: Default values are evaluated at call time (unlike Python) so `date` is always a new instance
export default function timeInformation(startDateStr, endDateStr, date = new Date()) {
  /**
   * @param {string} startDate - ISO date
   * @param {string} endDate - ISO date
   * @param {Date} [date=new Date()] - The date to compare the start and end dates
   * against, which defaults to "now".
   * @returns {TimeInformation}
   */
  let elapsedTimePercentage = 0;
  let pointInTime = 0;

  const startDate = startDateStr ? new Date(startDateStr) : null;
  const endDate = endDateStr ? new Date(endDateStr) : null;

  // In progress
  if (startDate && endDate && startDate <= date && date < endDate) {
    elapsedTimePercentage = ((date - startDate) * 100) / (endDate - startDate);
    pointInTime = 0;
    // In the future
  } else if (startDate && date < startDate) {
    elapsedTimePercentage = 0;
    pointInTime = -1;
    // In the past
  } else if (endDate && date >= endDate) {
    elapsedTimePercentage = 100;
    pointInTime = 1;
  } else {
    // If it reaches this point either:
    // - We couldn't check because of missing values and don't know anything
    // - We have only one of the values and it's not enough, so e.g. we have a
    //   startDate but it's in the past, so we don't know whether it's still live or it ended
    // We return the initial values (as if it was to start right now)
  }

  return { elapsedTimePercentage, pointInTime };
}
