import React from "react";
import styled from "styled-components";

const ProgressBarBody = styled.div`
  width: ${({ flat }) => (flat ? "100%" : "100px")};
  height: 4px;
  background: rgba(255, 255, 255, 0.5);
  margin: ${({ flat }) => (flat ? "0" : "0 8px")};
`;
const ProgressBarTime = styled.div`
  height: 100%;
  background: ${({ theme }) => theme.colors.primary};
`;

const ProgressBar = ({ progress, flat }) => {
  return (
    <ProgressBarBody flat={flat}>
      <ProgressBarTime style={{ width: `${progress || 0}%` }} />
    </ProgressBarBody>
  );
};

export default ProgressBar;
